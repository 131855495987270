import Prism from 'prismjs';

// Highlight all matching syntax
Prism.highlightAll();

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
